@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'sf-pro';
    src: url('../styles/assets/fonts/SF-Pro-Display-Regular.otf')
}

html {
    font-family: 'Regikan', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'SF-Pro-Display-Regular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

